import React from 'react'

import { Swiper, SwiperSlide } from 'swiper/react'

// Import Swiper styles
import 'swiper/css'
import 'swiper/css/pagination'

// import required modules
import { Pagination, Autoplay } from 'swiper'
import { OutboundLink } from 'gatsby-plugin-google-gtag'

import { graphql, useStaticQuery } from 'gatsby'
// import BackgroundImage from 'gatsby-background-image'

// export const BGImage: any = (imgName: string) => {
//   try {
//     const data = useStaticQuery(
//       graphql`
//         query {
//           allFile(filter: { extension: { regex: "/(jpg)|(png)|(jpeg)|(svg)|(webp)/" } }) {
//             edges {
//               node {
//                 base
//                 childImageSharp {
//                   fluid {
//                     ...GatsbyImageSharpFluid_withWebp
//                   }
//                 }
//               }
//             }
//           }
//         }
//       `,
//     )
//     const dt = data.allFile.edges.find(({ node }: any) => node.base === imgName)
//     return dt.node.childImageSharp.fluid
//   } catch (error) {
//     throw new Error('error:' + error)
//   }
// }

const Banner = () => {
  return (
    <div className="banner ">
      <Swiper
        slidesPerView={1}
        loop={true}
        pagination={false}
        autoplay={{
          delay: 5000,
        }}
        modules={[Pagination, Autoplay]}
        className="banner_slider"
      >
        {/* <SwiperSlide>
          <BackgroundImage
            className={'slide-item banner_image'}
            fluid={BGImage('1-bnh.png')}
          >
            <div className="overlayHeroIMg"></div>
            <div className="banner_text">
              <div className="container">
                <div className="text_inner textbanner">
                  <p className="subtitle">Experience & Warranty</p>
                  <h1 className="title">
                    Reliable MRO
                    <br /> Aircraft Services
                  </h1>
                  <a href="/services" className="thm-btn btn-rounded">
                    <span className="button_title">View More</span>
                  </a>
                </div>
              </div>
            </div>
          </BackgroundImage>
        </SwiperSlide>

        <SwiperSlide>
          <BackgroundImage
            className={'slide-item banner_image'}
            fluid={BGImage('sl2.webp')}
          >
            <div className="overlayHeroIMg"></div>

            <div className="banner_text">
              <div className="container">
                <div className="text_inner textbanner">
                  <p className="subtitle">Integrity & Professionalism</p>
                  <h1 className="title">
                    Your Safety Is
                    <br />
                    Our Priority
                  </h1>
                  <a href="/services" className="thm-btn btn-rounded">
                    <span className="button_title">View More</span>
                  </a>
                </div>
              </div>
            </div>
          </BackgroundImage>
        </SwiperSlide>

        <SwiperSlide>
          <BackgroundImage
            className={'slide-item banner_image'}
            fluid={BGImage('3.webp')}
          >
            <div className="overlayHeroIMg"></div>
            <div className="banner_text">
              <div className="container">
                <div className="text_inner textbanner">
                  <p className="subtitle">Quality & Responsiveness</p>
                  <h1 className="title">
                    We Are Your
                    <br /> Best Option
                  </h1>
                  <OutboundLink href="/services" className="thm-btn btn-rounded">
                    <span className="button_title">View More</span>
                  </OutboundLink>
                </div>
              </div>
            </div>
          </BackgroundImage>
        </SwiperSlide> */}


        <SwiperSlide>
          <div
            className="slide-item banner_image mbcp"
            style={{ backgroundImage: 'url(/images/banner/1.webp)' }}
          >
            <div className="banner_text">
              <div className="container">
                <div className="text_inner textbanner">
                  <p className="subtitle">Experience & Warranty</p>
                  <h1 className="title">
                    Reliable MRO
                    <br /> Aircraft Services
                  </h1>
                  <a href="/services" className="thm-btn btn-rounded">
                    <span className="button_title">View More</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div
            className="slide-item banner_image mbcp"
            style={{ backgroundImage: 'url(/images/sl2.webp)' }}
          >
            <div className="banner_text">
              <div className="container">
                <div className="text_inner textbanner">
                  <p className="subtitle">Integrity & Professionalism</p>
                  <h1 className="title">
                    Your Safety Is
                    <br />
                    Our Priority
                  </h1>
                  <a href="/services" className="thm-btn btn-rounded">
                    <span className="button_title">View More</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div
            className="slide-item banner_image mbcp"
            style={{ backgroundImage: 'url(/images/banner/3.webp)' }}
          >
            <div className="banner_text">
              <div className="container">
                <div className="text_inner textbanner">
                  <p className="subtitle">Quality & Responsiveness</p>
                  <h1 className="title">
                    We Are Your
                    <br /> Best Option
                  </h1>
                  <OutboundLink  href="/services" className="thm-btn btn-rounded">
                    <span className="button_title">View More</span>
                  </OutboundLink >
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
    </div>
  )
}

export default Banner
