import * as React from 'react'
import Layout from '@/components/Layout'
import Header from '@/components/Header'
import Home from '@/components/Home'
import Footer from '@/components/Footer'
import { useLocation } from '@reach/router'
import SEO from '@/components/seo'
import type { PageProps } from 'gatsby'

const Ho:React.FC<PageProps> = ({location}) => {
  const {state} = location
  return (
    <Layout>
      <SEO title="Home" canonical={useLocation().host} />
      <Header />
      <Home locationState={state} />
      <Footer />
    </Layout>
  )
}

export default Ho
