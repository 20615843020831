import React, { useEffect, useRef } from 'react'
import Banner from '@/components/Home/Banner'
import About from '@/components/Home/About'
import Services from '@/components/Home/Services'
import Cta from '@/components/Home/Cta'
import Work from '@/components/Home/Work'
import Team from '@/components/Home/Team'
// import Testimonial from '@/components/Home/Testimonial'
import Subscribe from '@/components/Home/Subscribe'
import CertificationBanner from './Certification'

type Props = {
  locationState: any
}
const Home: React.FC<Props> = ({ locationState }) => {
  const certDiv = useRef<HTMLDivElement>(null)
  useEffect(() => {
    if (locationState && locationState.isCertificate && certDiv.current) {
      certDiv.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'end',
      })
    }
  }, [])
  return (
    <>
      <Banner />
      <About />
      <Services />
      <Cta />
      <Work />
      <Team />
      {/* <Testimonial /> */}
      <div id='certificate' ref={certDiv}>
        <CertificationBanner />
      </div>
      <Subscribe />
    </>
  )
}

export default Home
