import React from 'react'
import { OutboundLink } from 'gatsby-plugin-google-gtag'

const Work = () => {
  return (
    <>
      <section
        className="section-parallax section-padding how-it-works-parent"
        style={{ backgroundImage: 'url(/images/howitworks.webp)' }}
      >
        <div className="container">
          <div className="section-header text-center">
            {/* <p className="section_count"></p> */}
            <h3 className="title text-white">What does our work offer you?</h3>
            <h5 className="mt-3">Reliability with every take off</h5>
          </div>
        </div>
      </section>
      <div className="how-it-works-sub">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-3 col-md-6">
              <OutboundLink href="/services" className="service_box style_2">
                <i className="fas fa-user-check icon"></i>
                <h5 className="title">Safety</h5>
                <p className="text">
                  Reliability throughout every aircraft maintenance event and is solely focused on
                  delivering only what’s right for you and your aircraft, guaranteeing a safe flight
                  for all our customers.
                </p>
              </OutboundLink>
            </div>
            <div className="col-lg-3 col-md-6">
              <OutboundLink href="/services" className="service_box style_2">
                <i className="fas fa-briefcase icon"></i>
                <h5 className="title">Capabilities</h5>
                <p className="text">
                  With an extensive stock of aviation spares and the necessary skills and
                  experience, we are capable of providing the highest level of aircraft maintenance
                  support possible.
                </p>
              </OutboundLink>
            </div>
            <div className="col-lg-3 col-md-6">
              <OutboundLink href="/services" className="service_box style_2">
                <i className="fas fa-puzzle-piece icon"></i>
                <h5 className="title">Components</h5>
                <p className="text">
                  Our extensive experience with aircraft accessories allows Jet Air MRO to be one of
                  the most comprehensive repair shops in the aviation maintenance industry.
                </p>
              </OutboundLink>
            </div>
            <div className="col-lg-3 col-md-6">
              <OutboundLink href="/services" className="service_box style_2">
                <i className="fas fa-lightbulb icon"></i>
                <h5 className="title">Solutions</h5>
                <p className="text">
                  We will provide you with comprehensive solutions tailored to your needs, improving
                  your aircraft's performance and ensuring your complete satisfaction.
                </p>
              </OutboundLink>
            </div>
            <div className="col-12 text-center">
              <OutboundLink href="/services" className="thm-btn btn-rounded">
                <span className="button_title">View More</span>
              </OutboundLink>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Work
