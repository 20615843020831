import React from 'react'
import { OutboundLink } from 'gatsby-plugin-google-gtag'
import Image from '@/components/Image'

const Cta = () => {
  return (
    <section className="section-padding section">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-5">
            <div className="section-header">
              {/* <p className="section_count"></p> */}
              <h3 className="title">We Provide Reliable Solutions</h3>
            </div>
            <p>
              We are proud to offer you safe solutions by highly qualified staff with extensive
              experience in providing maintenance, repair, and overhaul services for a variety of
              aircraft types, who will support you to optimize aircraft performance, thus reducing
              operating costs, and providing long-term savings.
            </p>
            <OutboundLink href="/about" className="thm-btn btn-rounded mb-xl-30">
              <span className="button_title">Read More</span>
            </OutboundLink>
          </div>
          <div className="col-lg-7">
            <div className="image mb-xl-30">
            <iframe
                src="https://www.youtube.com/embed/Eo_aywcWID8"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title="YouTube Video"
                style={{ width: '100%', height: '358px', borderRadius: '10px' }}
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Cta
