import React from 'react'
import './certification.css'
// @ts-ignore
import downloadFile from '@/assets/Jet-Air-MRO-FAA-Cert.pdf'
// @ts-ignore
import downloadEASAFile from '@/assets/Jet-Air-MRO-EASA-Cert..pdf'
// @ts-ignore
import downloadUKCAAFile from '@/assets/Jet Air MRO UK CAA Cert..pdf'

const CertificationBanner = () => {
  return (
    <>
      <div className="section-header text-center cert-title-wrp">
        {/* <p className="section_count"></p> */}
        <h3 className="title">Certificate</h3>
      </div>
      <section className="wrp">
        <div className="container">
          <div className="cert-w">
            <h2 className="cert-title">
              Air agency
              <br /> Certificate
            </h2>
            <h5>FAA, EASA & UK CAA CERTIFIED</h5>
          </div>
          <div className="img-cn">
            <div className="row">
              <div className="col do ft">
                <img src="/images/FAA-Cert.png" alt="img" />
                <div className="overlay-cr-one"></div>
                <a className="afirst" download href={downloadFile}>
                  <i className="fas fa-arrow-circle-down"></i>
                </a>
              </div>
              <div className="col sc">
                <img src="/images/easathumb.png" alt="img" />
                <div className="overlay-cr"></div>
                <a className="asecond" download href={downloadEASAFile}>
                  <i className="fas fa-arrow-circle-down"></i>
                </a>
              </div>
              <div className="col sc">
                <img src="/images/ukcaathumb.png" alt="img" />
                <div className="overlay-cr"></div>
                <a className="asecond" download href={downloadUKCAAFile}>
                  <i className="fas fa-arrow-circle-down"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default CertificationBanner
