import React, { useMemo } from 'react'
import { FormProvider } from '@/hook-form'
import RHInput from '@/hook-form/RHInput'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { saveSubscriber, SubscriberData } from '@/npc-api/subscriber'
import { GTM } from '@/utils/google-tag-manager'
import { GTMEventType } from '@/types/google-tag-manager.interface'

const Subscribe = () => {
  const ContactSchema = Yup.object().shape({
    email: Yup.string().required('Email is required').email(),
  })

  const defaultValues = useMemo(
    () => ({
      email: '',
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  const methods = useForm<SubscriberData>({
    resolver: yupResolver(ContactSchema),
    defaultValues,
  })

  const {
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = methods

  const onSubmit = async (data: SubscriberData) => {
    GTM.sendEvent({
      event: GTMEventType.RequestSubscription,
    })

    await saveSubscriber(data).then((e) => reset({ email: '' }))
  }
  return (
    <section
      className="section-padding section section-parallax contact_bg"
      style={{ backgroundImage: 'url(/images/contact_bg.webp)' }}
    >
      <div className="container">
        <div className="section-header text-center">
          {/* <p className="section_count"></p> */}
          <h3 className="title text-white">Subscribe To Our Newsletter</h3>
        </div>
        <FormProvider
          name="sbsssvfsub"
          methods={methods}
          onSubmit={handleSubmit(onSubmit)}
          className="row form_style col-sm-12 col-md-6 col-lg-8 mx-auto"
        >
          <div className="col-md-12">
            <div className="form-group">
              <label>Your Email</label>
              <RHInput
                name="email"
                type="text"
                className="form-control fmsub-input"
                placeholder="Enter your email"
                required={true}
              />
            </div>
          </div>

          <div className="col-lg-12">
            <div className="form-group">
              <button disabled={isSubmitting} type="submit" className="thm-btn btn-rounded w-100">
                <span className="button_title"> {isSubmitting ? '...Loading' : 'Subscribe'}</span>
              </button>
            </div>
          </div>
        </FormProvider>
      </div>
    </section>
  )
}

export default Subscribe
